
import React, { useEffect, useState } from 'react';
import { BsSquareHalf } from "react-icons/bs";
import './pricing.scss';
import { PageHeaderComp } from '../../components/pageHeader/pageHeader';
import { PackageCards } from '../../components/actionSelect/actionSelect'
import { useNavigate } from "react-router-dom";
import logo from '../../assets/images/claima_black.png';
import { myRequest } from '../lib';
import { notification } from 'antd';

const Pricing = () => {
    const navigate = useNavigate();
    // const [segment, setSegment] = useState(window.segment ?? 0)

    // const [plans, setPlans] = useState([])
    const [publicPlans, setPublicPlans] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() =>{
        // if('plansWithSummary' in window) {
        //     setPlans(window.plansWithSummary)
        // }
        setLoading(true)

        myRequest('post', 'orgPlansPublic')
        .then(data => data?.data)
        .then(data => {
            if(data?.error){
                notification.error({message: data?.message})
            }else{
                const wrangled = data?.data?.map(plan => (
                    {
                        name: plan.plan_name,
                        title: plan.plan_description,
                        state: {option: plan.plan_name, price: plan.plan_cost, sixPrice: plan.plan_six_cost, category: 'self pay'},
                        benefits: plan.benefits.map(ben => ben.plan_summary_text)
                    }
                ))
                setPublicPlans(wrangled)
            }
        }).finally(() => setLoading(false))
    }, [])

    // const loadOrgPlans = () => {
    //     setLoading(true)
    //     setPlans([])
    //     window.plansWithSummary = []
    //     const social_no = orgCode.current.input.value;
    //     myRequest('post', 'validate_org_code', { social_no })
    //     .then(resp => resp.data)
    //     .then(data => {
    //         if (data['error'] ){
    //             notification.error({message: data?.message})
    //         } else {
    //             notification.success({message: data?.message})
    //             myRequest('post', 'orgPlansReg', { id: data?.data?.org_id})
    //             .then(data => data?.data)
    //             .then(data => {
    //                 if(data?.error){
    //                     notification.error({message: data?.message})
    //                 }else{
    //                     const wrangled = data?.data?.map(plan => (
    //                         {
    //                             name: plan.plan_name,
    //                             title: plan.plan_description,
    //                             state: {option: plan.plan_name, price: plan.org_plan_cost, sixPrice: plan.org_plan_familty_cost, social_no, category: 'corporate'},
    //                             benefits: plan.benefits.map(ben => ben.plan_summary_text)
    //                         }
    //                     ))
    //                     console.log(wrangled)
    //                     setPlans(wrangled)
    //                     window.plansWithSummary = wrangled
    //                 }
    //             })
    //             .finally(() => setLoading(false)) 
    //         }
    //     })
    //     .finally(() => setLoading(false)) 
    // }

    return (
        <div className='pricing' style={{}}>
            <div className='pricing-image' style={{display: 'flex', justifyContent: 'center'}}>
                <img src={logo} style={{width: 100, height: 50, margin: "10px auto"}} alt='img'/>
            </div>
            <div className='pricing-container'>
                <PageHeaderComp title={<span style={{display: 'flex', alignItems: 'center', justifyItems:'center', justifyContent: 'center'}}><p><BsSquareHalf />&nbsp;Log Me In</p></span>} style={{ fontSize: "16px", color: "#276AFF", cursor: "pointer", marginLeft: "20px", marginTop: "10px", textAlign: "center" }} onClick={() => navigate('/login', { replace: true })} />
                <PackageCards data = {publicPlans} loading={loading}/>
            </div>
        </div>
    )
}

export default Pricing;