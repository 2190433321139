import React , {useEffect, useRef} from 'react';
import useLocalStorage from './useLocal';
import config from '../../assets/utils/config';

const AuthContext = React.createContext();

export function useAuth() {
    const context = React.useContext(AuthContext);

    if (context === undefined) {
        throw new Error('useAuth must be called within a AuthProvider');
    }
    return context;
}

export function AuthProvider({ children }) {
    const [user, setUser] = useLocalStorage(config.key.user);
    const [token, setToken] = useLocalStorage(config.key.token);
    const timerRef = useRef();

    const set = async (data) => {
        setToken(data?.jwt);
        setUser(data);
    }

    const update = async (data) => {
        data?.jwt && setToken(data?.jwt);
        setUser({...user, ...data});
    }

    const unset = async () => {
        setToken(null);
        setUser(null);
    }

    useEffect(() => {
        if(user){
            timerRef.current = setInterval(() => {
                if((Date.now() > new Date(user.expireAt * 1000))){
                    clearInterval(timerRef.current);
                    if(timerRef.current) clearInterval(timerRef.current)
                    unset()
                    setTimeout(() => {
                        window.location.assign('/login');
                    }, 2000);
                }
            }, 1000)
        }else{
            if(!['/login', '/reset', '/pricing'].includes(window.location.pathname)){
                window.location.assign('/login');
            }
        }
    }, [user]) //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <AuthContext.Provider value={{ set, unset, token, user, update }}>
            {children}
        </AuthContext.Provider>
    )
}