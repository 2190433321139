import Structure from "../../components/layout/index";
import React, { useEffect, useState } from 'react';
import { ButtonComponent } from '../../components/buttonComponent/buttonComponent';
import { PageHeaderComp } from '../../components/pageHeader/pageHeader';
import { useNavigate, } from "react-router-dom"
import { useNotifications } from '@mantine/notifications';
import { Form, Input, notification } from 'antd';
import lib from '../lib';
import helpers from '../../core/Helpers';
import './reset.scss';
import LogoHook from "../../components/logo";
import { useRef } from "react/cjs/react.production.min";
// import logo from '../../assets/images/icon/claima.png';

const Reset = () => {
    const [form] = Form.useForm();
    const [formLayout,] = useState('vertical');
    
    const navigate = useNavigate();
    const [token, setToken] = useState(new URLSearchParams(window.location.search).get('token'));
    const n = useRef(new URLSearchParams(window.location.search).get('n'));

    const [values, setValues] = React.useState({});
    const [loading, setLoading] = useState(false);
    const [, setVerifying] = useState(false);
    const notify = useNotifications();

    useEffect(() => {
        if (token) {
            lib.verify_reset_token({data: token?.split('|')})
            .then(res => res.data)
            .then(data => {
                if(data?.error){
                    setToken(null);
                    notification.error({message: data?.message})
                    navigate('/login')
                }
                setValues(d => ({...d, policy_no: data?.data?.policy_no}))
            })
            .finally(() => setVerifying(false))
        }
    }, []); //eslint-disable-line

    const handleReset = async () => {
        if(!Object.keys(values).length || !Object.keys(values).includes('policy_no')) {
            helpers.alert({ notifications: notify, icon: 'error', color: 'red', message: 'Kindly Supply Either of your Email, Policy Number or Phone Number' })
            return;
        }
        
        setLoading(true);

        let reqData = await lib.reset(values)

        if (reqData.status === 'error') {
            helpers.alert({ notifications: notify, icon: 'error', color: 'red', message: reqData.msg });
            // return;
        }else if (reqData?.data?.error === 1) {
            helpers.alert({ notifications: notify, icon: 'error', color: 'red', message: reqData.data.message });
            // return;
        }else if (reqData?.data?.error === 0) {
            helpers.alert({ notifications: notify, icon: 'success', color: 'green', message: reqData.data.message, duration: 5 });
            if(token){
                navigate('/login');
            }
        }

        setLoading(false);
    }; 

    return (
        <Structure className="login">
            <div className="rs-center" style={{background: 'transparent'}}>
                {/* <img src={logo} style={{width: 100, height: 50, margin: "10px 0"}} alt="img"/> */}
                <LogoHook />
            </div>
            {
                token?
                    <div className="rs-center">
                        <div className="app-login">
                            <div className="app-login__container">
                                <div className="app-login__content">
                                    {
                                    n.current && Number(n.current)? <PageHeaderComp title="New User Pasword Creation" />:
                                    <PageHeaderComp title="Pasword Reset Request" />
                                    }
                                    <div className="app-login__error">
                                    </div>
                                    <div className="p-fluid p-formgrid p-grid p-mx-5">
                                        <div style={{ width: '100%', marginTop: "35px" }} className="container">
                                            <div className="row">
                                                <Form layout={"vertical"} form={form} initialValues={{layout: formLayout }} >
                                                    <Form.Item label="Enter Password" required tooltip="This is a required field" >
                                                        <Input.Password onChange={e => setValues(d => ({ ...d, password: e.target.value }))} autoFocus value={values?.password} placeholder="*********" style={{ padding: "10px", borderRadius: "6px" }} />
                                                    </Form.Item>
                                                    <Form.Item label="Confirm Password" required tooltip="This is a required field" >
                                                        <Input.Password onChange={e => setValues(d => ({ ...d, confirm_password: e.target.value }))} value={values?.confirm_password} placeholder="*********" style={{ padding: "10px", borderRadius: "6px" }} />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        {/* <ButtonComponent style={{padding: '15px auto', width: 'fit-content'}} content={loading? <Loader type="Oval" color="blue" height={20} visible={loading} width={30} /> : 'Send Request'} disabled = {loading} onClick={handleReset} /> */}
                                                        <ButtonComponent style={{padding: '15px auto', width: 'fit-content'}} content={'Send Request'} disabled = {loading} onClick={handleReset} />
                                                        <div className="" style={{ marginTop: "30px" }}>
                                                            <PageHeaderComp title="Go to Login" style={{ fontSize: "16px", color: "#276AFF", cursor: "pointer" }} onClick={() => navigate('/login', { replace: true })} />
                                                            <PageHeaderComp title="Start Registration" style={{ fontSize: "16px", color: "#276AFF", cursor: "pointer" }} onClick={() => navigate('/register', { replace: true })} />
                                                        </div>
                                                    </Form.Item>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>:
                    <div className="login-center">
                        <div className="app-login">
                            <div className="app-login__container">
                                <div className="app-login__content">
                                    <PageHeaderComp title="Pasword Reset Request" />
                                    <div className="app-login__error">
                                    </div>
                                    <div className="p-fluid p-formgrid p-grid p-mx-5">
                                        <div style={{ width: '100%', marginTop: "35px" }} className="container">
                                            <div className="row">
                                                <Form layout={"vertical"} form={form} initialValues={{layout: formLayout }} >
                                                    <Form.Item label="Email or Policy or Phone number" required tooltip="This is a required field" >
                                                        <Input onChange={e => setValues(d => ({ ...d, policy_no: e.target.value }))} autoFocus value={values?.policy_no} placeholder="example@email.com" style={{ padding: "10px", borderRadius: "6px" }} />
                                                    </Form.Item>
                                                    <Form.Item>
                                                        {/* <ButtonComponent style={{padding: '15px auto', width: 'fit-content'}} content={loading? <Loader type="Oval" color="blue" height={20} visible={loading} width={30} /> : 'Send Request'} disabled = {loading} onClick={handleReset} /> */}
                                                        <ButtonComponent style={{padding: '15px auto', width: 'fit-content'}} content={'Send Request'} disabled = {loading} onClick={handleReset} />
                                                        <div className="" style={{ marginTop: "30px" }}>
                                                            <PageHeaderComp title="Go to Login" style={{ fontSize: "16px", color: "#276AFF", cursor: "pointer" }} onClick={() => navigate('/login', { replace: true })} />
                                                            <PageHeaderComp title="Start Registration" style={{ fontSize: "16px", color: "#276AFF", cursor: "pointer" }} onClick={() => navigate('/register', { replace: true })} />
                                                        </div>
                                                    </Form.Item>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </Structure>
    )
}

export default Reset;