import React, { Fragment, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate
} from 'react-router-dom';

import Pricing from './pricing';
import { routes } from './config';
import Login from './login';
import { useAuth } from '../core/hooks/useAuth';
import Helpers from '../core/Helpers';

const Logout = props => {
  const {set} = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    Helpers.logout(set);
    navigate('/login');
  }, []) //eslint-disable-line

  return(
    <></>
  )
}


const App = (props) => {

  const renderedRoutes = routes.map(AppRoute =>
    <Route key={AppRoute.link} path={AppRoute.link} element={<AppRoute.Component />}> </Route>
  )
  return (
    <Router>
      <Fragment>
        <Routes>
          <Route path='/' element={<Pricing />} />
          <Route path='/logout' element={<Logout />} />
          {renderedRoutes}
          <Route path='*' element={<Login />} />
        </Routes>
      </Fragment>
    </Router>
  );
}
export default App;
